<template>
  <div class="Form">
    <v-row class="Form__row fill-height" justify="center" align="center">
      <v-col cols="12" sm="10" lg="7" xl="8">
        <v-row no-gutters justify="center">
          <v-col class="text-center" cols="auto">
            <div>
              <span class="color-cream titles-big-x d-block bold bree-serif">{{
                contactSingleton.contact_title
              }}</span>
              <span class="color-cream d-block titles-medium mt-2 mt-xl-12">{{
                contactSingleton.contact_number
              }}</span>

              <span class="color-cream d-block titles-big mt-12 mt-xl-12">{{
                contactSingleton.contact_subtitle
              }}</span>
            </div>
          </v-col>
          <v-col cols="11" sm="12" class="text-center mt-12">
            <v-form ref="form" lazy-validation v-model="valid">
              <v-text-field dark label="Name" v-model="form.name" :rules="emptyRules" required></v-text-field>
              <v-text-field dark label="Email" v-model="form.email" :rules="emailRules" required></v-text-field>
              <v-text-field dark label="Phone Number" v-model="form.phone" ></v-text-field>
              <v-text-field dark label="Message" v-model="form.message" :rules="emptyRules" required></v-text-field>

              <v-btn
                class="btn-general not-text-transform mt-12"
                color="#FFC979"
                elevation="0"
                rounded
                :ripple="false"
                :disabled="!valid"
                @click="validate"
              >
                <span class=" home__content--btn__span continuos-texts-2 ">Send</span>
              </v-btn>
            </v-form>

            <v-snackbar v-model="snackbar" multi-line color="#174860" timeout="12000">
              <span class="only-22 line-height-some-titles-2" style="color:white">Thank you for reaching us, our sales team will be happy to contact you as soon as possible</span>

              <template v-slot:action="{ attrs }">
                
                  <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                  >
                  Close
                  </v-btn>
              </template>
            </v-snackbar>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Form",
  data() {
    return {
      contactSingleton: {},
      valid: true,
      snackbar:false,

      
      form:{
        name: '',
        email: '',
        phone: '',
        message: ''

      },
      emptyRules: [
        validation => !!validation || 'El Campo  es requerido',
      ],
      emailRules: [
            validation => !!validation || 'Correo es requerido',
            validation =>  /.+@.+\..+/.test(validation) || 'El formato no es correcto',
      ],
      

      
    };
  },
  beforeCreate() {
    //fetch para el singleton de contacto
    fetch(
      "https://www.cymcms.actstudio.xyz/api/singletons/get/contact?token=aa5f1f62f706c89e9dae0ea5e2400a"
    )
      .then((data) => data.json())
      .then((data) => (this.contactSingleton = data));
  },
  methods:{
    sendInfo: function () {
      fetch(
        "https://www.cymcms.actstudio.xyz/api/forms/submit/ContactForm?token=aa5f1f62f706c89e9dae0ea5e2400a",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            form: this.form,
          }),
        }
      )
        .then((entry) => entry.json())
        .then(()=> this.snackbar = true)
        .then(() => this.$refs.form.reset());
    },

    validate: function(){
      var result = this.$refs.form.validate();
      if (result) {
        this.sendInfo();
      }

    },
  }
};
</script>